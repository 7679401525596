<template>
  <div>
    <van-sticky>
      <title-bar2 title="智慧调剂" />
    </van-sticky>
    <div class="content-box">
      <div class="guide-box">
        <span class="num-box">
          <span class="num1">1</span>
          <span class="num2">/</span>
          <span class="num2">3</span>
        </span>
        <span class="txt">你报考的信息是？</span>
      </div>
      <div class="card-box">
        <smart-adjust-form ref="smartAdjustForm" :formData.sync="formData"></smart-adjust-form>
        <div style="margin: 15px">
          <van-row gutter="20">
            <van-col span="12">
              <van-button round block size="small" type="default" native-type="button" @click="onClearForm">清空</van-button>
            </van-col>
            <van-col span="12">
              <van-button round block size="small" color="#FE5E03" @click="onSubmitAnalyze">开始调剂分析</van-button>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="admit-divider"></div>
      <div class="guide-box">
        <span class="num-box">
          <span class="num1">2</span>
          <span class="num2">/</span>
          <span class="num2">3</span>
        </span>
        <span class="txt">你2025年的调剂分析</span>
      </div>
      <div v-if="analysisResult" class="card-box">
        <div class="result-box">
          <header-entry :img-size="16">
            <div class="title">国家线和学校复试线</div>
          </header-entry>
          <div class="row_box">
            <div class="major_box" v-if="analysisResult.VolunteerMinScore">
              <div class="major1">一志愿录取最低：</div>
              <div class="major2">{{ analysisResult.UniversityName }} {{ analysisResult.CollegeName }} {{ analysisResult.MajorCode }}{{ analysisResult.MajorName }} {{ analysisResult.VolunteerMinScore }}</div>
            </div>
            <div class="major_box">
              <div class="major1">学校复试线：</div>
              <div class="major2">{{ analysisResult.UniversityName }} {{ analysisResult.CollegeName }} {{ analysisResult.MajorCode }}{{ analysisResult.MajorName }} {{ analysisResult.RetestLine }}</div>
            </div>
            <div class="major_box">
              <div class="major1">国家线：</div>
              <div class="major2">{{ analysisResult.MajorCode }}{{ analysisResult.MajorName }} {{ analysisResult.NationalLine }}</div>
            </div>
            <div class="major_box">
              <div class="major1">跨学科调剂A区国家线：</div>
              <div class="major2">{{ analysisResult.NationalLineA }}</div>
            </div>
            <div class="major_box">
              <div class="major1">跨学科调剂B区国家线：</div>
              <div class="major2">{{ analysisResult.NationalLineB }}</div>
            </div>
          </div>
          <div class="bottom-line"></div>
          <header-entry :img-size="16">
            <div class="title">调剂分析结果</div>
          </header-entry>
          <div class="row_box">
            <template v-if="analysisResult.AnalysisVerdict">
              <div class="txt">{{ analysisResult.AnalysisVerdict }}</div>
            </template>
            <div v-else class="empty-txt">暂无</div>
          </div>
          <div class="bottom-line"></div>


          <div v-if="analysisResult.AdjustDirections.length > 0" class="admit-content">
            <header-entry :img-size="16">
              <div class="title">调剂去向</div>
              <van-image v-if="!isMembership" :src="lockIcon" class="adjust-lock" @click="onFormUnlockClick"></van-image>
            </header-entry>
            <div v-if="isMembership">
              <van-swipe autoplay="-" indicator-color="#FE5E03">
                <van-swipe-item v-for="(item, index) in analysisResult.AdjustDirections" :key="index">
                  <div>
                    <div class="admit-year">调剂年份：{{ item.AdjustYear }}年</div>
                    <div class="adjust-title">调剂录取院校-过国家线未被本专业录取考生最终调剂去向学校</div>
                    <div class="adjust-text" v-for="(item, index) in adjustDirectionSchools(item.AdjustDirectionSchool)" :key="index">{{ item }}</div>
                    <van-image :src="adjustRosterImage(item.AdjustYear)" class="admit-img" @click="onAdjustPreviewClick(item.AdjustYear)">
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                  </div>
                </van-swipe-item>
              </van-swipe>
              <div class="admit-tip">
                <span>温馨提示: 点击图片可以查看高清原图。</span>
              </div>
            </div>
          </div>
          <!-- <template v-if="analysisResult.AdjustDirectionGuid">
            <header-entry :img-size="16">
              <div class="title">调剂去向：2023年</div>
              <van-image v-if="!analysisResult.AdjustDirectionUnlocked" :src="lockIcon" class="adjust-lock" @click="onFormUnlockClick"></van-image>
            </header-entry>
            <div v-if="analysisResult.AdjustDirectionUnlocked" class="row_box">
              <div class="adjust-title">调剂录取院校-过国家线未被本专业录取考生最终调剂去向学校</div>
              <div class="adjust-text" v-for="item in adjustDirectionSchools">{{ item }}</div>
              <van-image :src="adjustRosterImage" class="adjust-img" @click="onAdjustPreviewClick">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <div class="adjust-tip">
                <span>温馨提示: 点击图片可以查看高清原图。</span>
              </div>
            </div>
            <div class="bottom-line"></div>
          </template> -->
          <header-entry :img-size="16">
            <div class="title">可调剂省区及学位类别</div>
          </header-entry>
          <div class="row_box">

            <template v-if="analysisResult.AdjustPartitionDegreeTypes && analysisResult.AdjustPartitionDegreeTypes.length > 0">
              <div v-for="item in analysisResult.AdjustPartitionDegreeTypes" class="txt">{{ item.Partition }}区省份 {{ item.DegreeType }}</div>
            </template>
            <div v-else class="empty-txt">暂无</div>
          </div>
          <div class="bottom-line"></div>
          <header-entry :img-size="16">
            <div class="title">可调剂专业</div>
          </header-entry>
          <div class="row_box">

            <template v-if="majorGroup">
              <div v-for="(val, key) in majorGroup" class="major_box">
                <div class="major1">{{ key }}</div>
                <div class="major2_box">
                  <div v-for="item in val" class="major2">{{ item.MajorCode }}{{ item.MajorName }}</div>
                </div>
              </div>
            </template>
            <div v-else class="empty-txt">暂无</div>
          </div>
        </div>
      </div>
      <div v-else class="card-box">
        <div class="empty-txt">请正确填写报考信息，点“开始调剂分析”获取数据。</div>
      </div>
      <div class="admit-divider"></div>
      <div class="admit-between">
        <div class="guide-box">
          <span class="num-box">
            <span class="num1">3</span>
            <span class="num2">/</span>
            <span class="num2">3</span>
          </span>
          <span class="txt">以下是为你推荐的调剂院校</span>
        </div>
        <van-button v-if="analysisResult" round size="mini" color="#FE5E03" class="report-button" @click="onShowStudent">生成调剂报告</van-button>
      </div>
      <div v-if="analysisResult">
        <div class="tabs-box" v-if="analysisResult.AdjustMajors && analysisResult.AdjustMajors.length > 0">
          <van-tabs v-model="active" @change="onChange">
            <!-- <van-tab :title="`全部 ${countAll}`"></van-tab> -->
            <van-tab :title="`稳妥 ${countSafer}`"></van-tab>
            <van-tab :title="`冲击 ${countStrike}`"></van-tab>
            <van-tab :title="`其他 ${countOther}`"></van-tab>
            <van-tab :title="`对比 ${countCompare}`"></van-tab>
          </van-tabs>

          <template v-if="active != 3">
            <smart-adjust-filter :learningWayList="filterLearningWayList" :degreeTypeList="filterDegreeTypeList" :belongCategoryList="filterBelongCategoryList" :firstDisciplineList="filterFirstDisciplineList" :adjustMajorList="filterAdjustMajorList" :aProvinceList="filterAProvinceList" :bProvinceList="filterBProvinceList" :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" :filter5.sync="filter5" :filter6.sync="filter6" :filter7.sync="filter7" @filter="onFilter"></smart-adjust-filter>
            <van-list v-if="majorInfos.length > 0" class="admit-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
              <smart-adjust-item v-for="(admit, index) in majorInfos" class="admit-item" :key="index" :school="admit.UniversityName" :college="admit.CollegeName" :major="admit.MajorName" :code="admit.MajorCode" :province="admit.UniversityProvince" :partition="admit.UniversityPartition" :characteristic="admit.UniversityCharacteristic" :year="admit.MajorEnrollmentYear" :learnway="admit.MajorLearningWay" :isUnlocked="isMembership" :isAdjust="admit.IsAdjust" :admitInfoList="admit.AdmitInfos" :img="schoolImg(admit.UniversityCode)" :compareVisible="compareVisible(admit)" @item-click="onItemClick(admit)" @unlock-click="onItemUnlockClick(admit)" @adjust-click="onItemAdjustClick(admit)" @compare-click="onAddCompareClick(admit)"></smart-adjust-item>
            </van-list>
            <div v-else class="card-box">
              <div class="empty-txt">无数据</div>
            </div>
          </template>

          <template v-else>
            <el-table :data="tableData" border>
              <el-table-column fixed align="center" prop="column" label="院校" width="110">
              </el-table-column>
              <el-table-column v-for="(column, index) in tableColumns" :key="index" :prop="column.prop" :label="column.label" width="200">
                <template slot="header" slot-scope="scope">
                  <div>
                    <span>{{ column.label }}</span>
                    <i class="el-icon-close" title="移除" @click="onDelCompareClick(index)"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </div>
        <div v-else class="card-box">
          <div class="empty-txt">暂无</div>
        </div>
      </div>
      <div v-else class="card-box">
        <div class="empty-txt">请正确填写报考信息，点“开始调剂分析”获取数据。</div>
      </div>
    </div>

    <van-image-preview closeable :show-index="false" v-model="adjustPreview" :images="previewAdjustRosterImages"></van-image-preview>

    <van-dialog v-model="studentDialog" :showConfirmButton="false" title="填写个人信息">
      <student-dialog2 @submit="onSubmitReport" @cancel="onCancelReport"></student-dialog2>
    </van-dialog>
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import StudentDialog2 from "@/components/MT/StudentDialog2";
import SmartAdjustForm from "@/components/MT/SmartAdjustForm";
import SmartAdjustFilter from "@/components/MT/SmartAdjustFilter";
import SmartAdjustItem from "@/components/MT/SmartAdjustItem";
import RadioItemGroup from "@/components/MT/RadioItemGroup";
import RadioItem from "@/components/MT/RadioItem";
import HeaderEntry from "@/components/MT/HeaderEntry";
import Config from "@/api/service";
import Tools from "@/tool";
import Manager from "@/manager";
export default {
  name: "MTModule",
  components: {
    TitleBar2,
    StudentDialog2,
    SmartAdjustForm,
    SmartAdjustFilter,
    SmartAdjustItem,
    RadioItemGroup,
    RadioItem,
    HeaderEntry,
  },
  data() {
    return {
      formData: {
        universityCode: null,
        collegeCode: null,
        majorCode: null,
        majorLearningWay: '全日制',
        politicalScore: null,
        englishScore: null,
        majorScore1: null,
        majorScore2: null,
      },
      isMembership: false,
      studentDialog: false,
      disabled: false,
      active: 0,
      index: 0,
      total: 0,
      countAll: 0,
      countSafer: 0,
      countStrike: 0,
      countOther: 0,
      loading: false,
      finished: false,
      adjustPreview: false,
      previewAdjustRosterImages: [],
      filter1: [],
      filter2: [],
      filter3: '',
      filter4: [],
      filter5: [],
      filter6: [],
      filter7: '',
      majorInfos: [],
      admitCompares: [],
      analysisParams: null,
      analysisResult: null,
      analysisFilter: null,
      lockIcon: require('@/assets/images/comm/lock.png'),
    };
  },
  computed: {
    schoolImg() {
      return function (code) {
        if (code) {
          return `${Config.school.logo}?SchoolCode=${code}`;
        }
      }
    },
    compareVisible() {
      return function (admitInfo) {
        return this.admitCompares.filter(p => p.AdmitGuid == admitInfo.AdmitGuid).length == 0 && this.isMembership;
      }
    },
    countCompare() {
      return this.admitCompares.length;
    },
    tableColumns() {
      var columns = [];
      for (var i = 0; i < this.admitCompares.length; i++) {
        columns.push({
          prop: 'column' + i,
          label: this.admitCompares[i].UniversityName,
        });
      }
      return columns;
    },
    tableData() {
      var data = [
        { column: "学院" },
        { column: "专业" },
        { column: "研究方向" },
        { column: "初试科目" },
        { column: "复试科目" },
        { column: "拟招生人数" },
        { column: "录取年份" },
        { column: "录取总人数" },
        { column: "一志愿录取" },
        { column: "一志愿最低分" },
        { column: "一志愿最高分" },
        { column: "目标分数" },
        { column: "调剂人数" },
        { column: "调剂最低分" },
        { column: "调剂最高分" },
      ];
      for (var i = 0; i < this.admitCompares.length; i++) {
        data[0]['column' + i] = this.admitCompares[i].CollegeName;
        data[1]['column' + i] = this.admitCompares[i].MajorCode + this.admitCompares[i].MajorName;
        data[2]['column' + i] = this.admitCompares[i].MajorResearchDirection;
        data[3]['column' + i] = this.admitCompares[i].MajorFirstSubject;
        data[4]['column' + i] = this.admitCompares[i].MajorSecondSubject;
        data[5]['column' + i] = this.admitCompares[i].MajorPlannedEnrollment;
        data[6]['column' + i] = this.admitCompares[i].AdmitYear;
        data[7]['column' + i] = this.admitCompares[i].AdmitTotalNumber;
        data[8]['column' + i] = this.admitCompares[i].AdmitVolunteerNumber;
        data[9]['column' + i] = this.admitCompares[i].AdmitVolunteerMinScore;
        data[10]['column' + i] = this.admitCompares[i].AdmitVolunteerMaxScore;
        data[11]['column' + i] = this.admitCompares[i].AdmitTargetScore;
        data[12]['column' + i] = this.admitCompares[i].AdmitAdjustedNumber;
        data[13]['column' + i] = this.admitCompares[i].AdmitAdjustMinScore;
        data[14]['column' + i] = this.admitCompares[i].AdmitAdjustMaxScore;
      }
      return data;
    },
    majorGroup() {
      if (this.analysisResult && this.analysisResult.AdjustMajors) {
        var groups = {};
        groups = this.analysisResult.AdjustMajors.reduce((acc, currentValue) => {
          let key = currentValue.MajorFirstDiscipline;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(currentValue);
          return acc;
        }, {});
        return groups;
      }
      return null;
    },
    totalScore() {
      var total = Number(this.formData.politicalScore) + Number(this.formData.englishScore) + Number(this.formData.majorScore1) + Number(this.formData.majorScore2);
      if (isNaN(total)) {
        return 0;
      } else {
        return total;
      }
    },
    adjustRosterImage() {
      return function (adjustYear) {
        if (this.analysisParams && this.analysisParams.universityCode && this.analysisParams.collegeCode && this.analysisParams.majorCode && adjustYear && this.$store.getters.isLogin) {
          return Config.admit.adjustRosterImage + `?UniversityCode=${this.analysisParams.universityCode}&CollegeCode=${this.analysisParams.collegeCode}&MajorCode=${this.analysisParams.majorCode}&AdjustYear=${adjustYear}&Token=${this.$store.state.token}`;
        }
        return null;
      }
    },
    adjustDirectionSchools() {
      return function (adjustDirectionSchool) {
        if (adjustDirectionSchool) {
          return adjustDirectionSchool.split('；');
        } else {
          return "";
        }
      }
    },
    filterLearningWayList() {
      return this.analysisFilter ? this.analysisFilter.LearningWays : [];
    },
    filterDegreeTypeList() {
      return this.analysisFilter ? this.analysisFilter.DegreeTypes : [];
    },
    filterBelongCategoryList() {
      return this.analysisFilter ? this.analysisFilter.Belongcategorys : [];
    },
    filterFirstDisciplineList() {
      if (this.filter3) {
        //获取分类前两位
        var category = this.filter3.substring(0, 2);
        //将匹配的专业排在首位
        this.analysisResult.AdjustMajors.sort((a, b) => {
          if (a.MajorCode === this.analysisResult.MajorCode && b.MajorCode !== this.analysisResult.MajorCode) {
            return -1;
          }
          if (a.MajorCode !== this.analysisResult.MajorCode && b.MajorCode === this.analysisResult.MajorCode) {
            return 1;
          }
          return 0;
        });
        //过滤学科类别
        var items = this.analysisResult.AdjustMajors.filter(p => p.MajorFirstDiscipline.startsWith(category));
        var arr = items.map(p => p.MajorFirstDiscipline);
        var result = [...new Set(arr)];
        return result;
      }
      return [];
    },
    filterAdjustMajorList() {
      if (this.filter4.length > 0) {
        var result = [];
        for (const item of this.filter4) {
          var items = this.analysisResult.AdjustMajors.filter(p => p.MajorFirstDiscipline == item);
          result.push(...items);
        }
        return result;
      }
      return [];
    },
    filterAProvinceList() {
      return this.analysisFilter ? this.analysisFilter.AProvinces : [];
    },
    filterBProvinceList() {
      return this.analysisFilter ? this.analysisFilter.BProvinces : [];
    },
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      if (this.$route.query.universityCode)
        this.formData.universityCode = this.$route.query.universityCode;
      if (this.$route.query.collegeCode)
        this.formData.collegeCode = this.$route.query.collegeCode;
      if (this.$route.query.majorCode)
        this.formData.majorCode = this.$route.query.majorCode;
      if (this.$route.query.majorLearningWay)
        this.formData.majorLearningWay = this.$route.query.majorLearningWay;
      if (this.$route.query.politicalScore)
        this.formData.politicalScore = this.$route.query.politicalScore;
      if (this.$route.query.englishScore)
        this.formData.englishScore = this.$route.query.englishScore;
      if (this.$route.query.majorScore1)
        this.formData.majorScore1 = this.$route.query.majorScore1;
      if (this.$route.query.majorScore2)
        this.formData.majorScore2 = this.$route.query.majorScore2;
    },
    //获取是否购买大数据会员
    getIsMembership() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      this.$axios
        .post(Config.member.purchased, model)
        .then((res) => {
          this.isMembership = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取分析结果
    getAnalysisResult() {
      this.$toast.loading({ message: "正在分析中...", duration: 0, forbidClick: true });
      this.filter1 = [];
      this.filter2 = [];
      this.filter3 = '';
      this.filter4 = [];
      this.filter5 = [];
      this.filter6 = [];
      this.filter7 = '';
      this.analysisResult = null;
      this.analysisParams = Tools.clone(this.formData);
      this.majorInfos = [];
      this.index = 0;
      this.total = 0;
      var model = {
        UniversityCode: this.analysisParams.universityCode,
        CollegeCode: this.analysisParams.collegeCode,
        MajorCode: this.analysisParams.majorCode,
        MajorLearningWay: this.analysisParams.majorLearningWay,
        PoliticalScore: this.analysisParams.politicalScore,
        EnglishScore: this.analysisParams.englishScore,
        MajorScore1: this.analysisParams.majorScore1,
        MajorScore2: this.analysisParams.majorScore2,
      };
      return this.$axios
        .post(Config.smartAdjust.analysisResult, model)
        .then((res) => {
          this.analysisResult = res.data.Data;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    //获取分析结果过滤条件
    getFilterConditions() {
      if (this.analysisResult && this.analysisResult.AdjustMajors && this.analysisResult.AdjustMajors.length > 0) {
        this.filter3 = '';
        var model = {
          AdjustMajorCodes: this.analysisResult.AdjustMajors.map(p => p.MajorCode),
          AdjustPartitionDegreeTypes: this.analysisResult.AdjustPartitionDegreeTypes,
        };
        return this.$axios
          .post(Config.smartAdjust.filter.conditionList, model)
          .then((res) => {
            this.analysisFilter = res.data.Data;
            if (this.filterBelongCategoryList.length > 0) {
              this.filter3 = this.filterBelongCategoryList[0];
            }
            if (this.filterFirstDisciplineList.length > 0) {
              this.filter4 = [this.filterFirstDisciplineList[0]];
            }
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }
    },
    //获取推荐专业数量
    getRecommendMajorCount() {
      if (this.analysisResult && this.analysisResult.AdjustMajors && this.analysisResult.AdjustMajors.length > 0) {
        var model = {
          UniversityCode: this.analysisResult.UniversityCode,
          CollegeCode: this.analysisResult.CollegeCode,
          MajorCode: this.analysisResult.MajorCode,
          TotalScore: this.totalScore,
          AdjustMajorCodes: this.analysisResult.AdjustMajors.map(p => p.MajorCode),
          AdjustPartitionDegreeTypes: this.analysisResult.AdjustPartitionDegreeTypes,
          MajorLearningWays: this.filter1,
          MajorDegreeTypes: this.filter2,
          MajorBelongcategory: this.filter3,
          MajorBelongFirstDisciplines: this.filter4,
          MajorCodes: this.filter5,
          UniversityProvinces: this.filter6,
          UniversityCharacteristic: this.filter7,
        };
        this.$axios
          .post(Config.smartAdjust.majorCount, model)
          .then((res) => {
            let data = res.data.Data;
            this.countAll = data.CountAll;
            this.countSafer = data.CountSafer;
            this.countStrike = data.CountStrike;
            this.countOther = data.CountOther;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }
    },
    //获取推荐专业列表
    getRecommendMajorList() {
      if (this.analysisResult && this.analysisResult.AdjustMajors && this.analysisResult.AdjustMajors.length > 0) {
        var model = {
          SearchType: this.active + 1,
          UniversityCode: this.analysisResult.UniversityCode,
          CollegeCode: this.analysisResult.CollegeCode,
          MajorCode: this.analysisResult.MajorCode,
          PoliticalScore: this.analysisResult.PoliticalScore,
          EnglishScore: this.analysisResult.EnglishScore,
          MajorScore1: this.analysisResult.MajorScore1,
          MajorScore2: this.analysisResult.MajorScore2,
          AdjustMajorCodes: this.analysisResult.AdjustMajors.map(p => p.MajorCode),
          AdjustPartitionDegreeTypes: this.analysisResult.AdjustPartitionDegreeTypes,
          MajorLearningWays: this.filter1,
          MajorDegreeTypes: this.filter2,
          MajorBelongcategory: this.filter3,
          MajorBelongFirstDisciplines: this.filter4,
          MajorCodes: this.filter5,
          UniversityProvinces: this.filter6,
          UniversityCharacteristic: this.filter7,
          TotalScore: this.totalScore,
          PageIndex: ++this.index,
          PageSize: 20,
        };
        this.$axios
          .post(Config.smartAdjust.majorList, model)
          .then((res) => {
            let data = res.data.Data;
            this.loading = false;
            this.total = data.TotalCount;
            this.majorInfos.push(...data.RecommendMajorInfos);
            if (this.index >= data.PageCount) {
              this.finished = true;
            }
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }
    },
    //获取报录数据对比列表
    getAdmitCompareInfos() {
      if (!this.$store.getters.isLogin) {
        return
      }
      var model = {};
      return this.$axios
        .post(Config.smartAdjust.compare.list, model)
        .then((res) => {
          this.admitCompares = res.data.Data.AdmitCompareInfos;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //添加报录数据对比记录
    addAdmitCompareInfo(admit) {
      var model = {
        UniversityCode: admit.UniversityCode,
        CollegeCode: admit.CollegeCode,
        MajorCode: admit.MajorCode,
        MajorLearningWay: admit.MajorLearningWay,
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.smartAdjust.compare.add, model)
        .then((res) => {

        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //删除报录数据对比记录
    delAdmitCompareInfo(compareGuid) {
      var model = {
        CompareGuid: compareGuid,
      };
      return this.$axios
        .post(Config.smartAdjust.compare.delete, model)
        .then((res) => {

        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //自动加载数据
    autoLoadData() {
      if (!Tools.isEmpty(this.formData.universityCode) && !Tools.isEmpty(this.formData.collegeCode) && !Tools.isEmpty(this.formData.majorCode)) {
        this.$refs.smartAdjustForm.autoLoadData();
        this.onSubmitAnalyze();
      }
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("智慧调剂");
    },
    //选择大学改变
    onSelectChange1() {
      this.popup2 = false;
      this.formData.collegeCode = null;
      this.formData.majorCode = null;
      this.formData.majorLearningWay = null;
      this.collegeInfos = [];
      this.majorInfos = [];
      this.getAllCollegeList();
      this.getAllMajorList();
    },
    //选择学院改变
    onSelectChange2() {
      this.popup3 = false;
      this.formData.majorCode = null;
      this.formData.majorLearningWay = null;
      this.majorInfos = [];
      this.getAllMajorList();
    },
    //选择专业学习方式改变
    onSelectChange4() {
      this.popup5 = false;
    },
    //点击过滤
    onFilter() {
      this.onReload();
    },
    //重新加载列表
    onReload() {
      this.finished = false;
      this.loading = true;
      this.index = 0;
      this.majorInfos = [];
      this.getRecommendMajorCount();
      this.getRecommendMajorList();
    },
    //加载当前列表
    onLoad() {
      this.getRecommendMajorList();
    },
    //选项卡切换
    onChange() {
      this.onReload();
    },
    //点击清空表单填写
    onClearForm() {
      this.$refs.smartAdjustForm.resetData();
    },
    //点击发送验证码
    onSendCaptcha() {
      if (!Tools.checkPhone(this.formData.studentPhone)) {
        this.$toast.fail("请输入正确的手机号码。");
      } else {
        //发送验证码
        var model = {
          Phone: this.formData.studentPhone,
        };
        this.$axios
          .post(Config.sms.sendCommonMessage, model)
          .then((res) => {
            this.disabled = true;
            this.$toast.success("验证码发送成功。");
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }
    },
    //点击开始调剂分析
    onSubmitAnalyze() {
      this.$refs.smartAdjustForm.validate().then(async () => {
        await this.getAnalysisResult();
        await this.getFilterConditions();
        this.getRecommendMajorList();
        this.getRecommendMajorCount();
      }).catch(() => {

      })
    },
    //弹窗填写学生报考信息
    onShowStudent() {
      this.studentDialog = true;
    },
    //生成调剂报告
    onSubmitReport(formData) {
      if (this.analysisResult) {
        if (!this.$store.getters.isLogin) {
          this.dialog = true;
        } else {
          this.studentDialog = false;
          //添加报告生成任务
          var model = {
            ProxyCode: this.$store.state.proxyCode,
            UniversityCode: this.analysisResult.UniversityCode,
            CollegeCode: this.analysisResult.CollegeCode,
            MajorCode: this.analysisResult.MajorCode,
            PoliticalScore: this.analysisResult.PoliticalScore,
            EnglishScore: this.analysisResult.EnglishScore,
            MajorScore1: this.analysisResult.MajorScore1,
            MajorScore2: this.analysisResult.MajorScore2,
            StudentName: formData.studentName,
            StudentPhone: formData.studentPhone,
            FilterMajorLearningWays: this.filter1,
            FilterMajorDegreeTypes: this.filter2,
            FilterMajorBelongcategory: this.filter3,
            FilterMajorBelongFirstDisciplines: this.filter4,
            FilterMajorCodes: this.filter5,
            FilterUniversityProvinces: this.filter6,
            FilterUniversityCharacteristic: this.filter7,
          };
          this.$axios
            .post(Config.smartAdjust.reportGenerate, model)
            .then((res) => {
              var result = res.data.Data;
              if (result.ReportState == -1) {
                //跳转订单提交
                this.$router.push({
                  name: "SubmitMemberOrder",
                  params: {
                    proxyCode: this.$store.state.proxyCode
                  }
                });
                // this.$router.push({
                //   name: "OrderSubmit3",
                //   params: {
                //     proxyCode: this.$store.state.proxyCode
                //   },
                //   query: {
                //     universityCode: this.analysisResult.UniversityCode,
                //     universityName: this.analysisResult.UniversityName,
                //     collegeCode: this.analysisResult.CollegeCode,
                //     collegeName: this.analysisResult.CollegeName,
                //     majorCode: this.analysisResult.MajorCode,
                //     majorName: this.analysisResult.MajorName,
                //     politicalScore: this.analysisResult.PoliticalScore,
                //     englishScore: this.analysisResult.EnglishScore,
                //     majorScore1: this.analysisResult.MajorScore1,
                //     majorScore2: this.analysisResult.MajorScore2,
                //     studentName: formData.studentName,
                //     studentPhone: formData.studentPhone,
                //   }
                // });
              } else {
                if (this.countAll > 1000) {
                  this.$dialog({ title: '提示', message: '调剂报告仅支持小于1000条数据的报告生成，请优化减少调剂院校总数量。', });
                } else {
                  if (result.ReportState == 0) {
                    this.$dialog({ title: '提示', message: '报告正在生成中，请到我的资料里面查看生成进度。', });
                  } else {
                    this.$dialog({ title: '提示', message: '报告已生成完毕，请到我的资料里面下载。', });
                  }
                }
              }
            })
            .catch((error) => {
              this.$dialog({ title: "错误", message: error.message });
            });
        }
      }
    },
    //取消生成报告
    onCancelReport() {
      this.studentDialog = false;
    },
    //点击解锁填报调剂去向
    onFormUnlockClick() {
      this.$router.push({
        name: 'SubmitMemberOrder',
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
    //点击解锁推荐调剂数据
    onItemUnlockClick(admit) {
      this.$router.push({
        name: "SubmitMemberOrder",
        params: {
          proxyCode: this.$store.state.proxyCode
        }
      });
    },
    //点击调剂
    onItemAdjustClick(admit) {
      this.$router.push({
        name: "Adjust1",
        params: {
          proxyCode: this.$store.state.proxyCode,
          year: admit.MajorEnrollmentYear,
          universityCode: admit.UniversityCode,
          collegeCode: admit.CollegeCode,
          majorCode: admit.MajorCode,
          majorLearnway: admit.MajorLearningWay,
        }
      });
    },
    //点击专业项
    onItemClick(admit) {
      if (this.isMembership) {
        this.$router.push({
          name: 'Admit1',
          params: {
            proxyCode: this.$store.state.proxyCode,
            universityCode: admit.UniversityCode,
            collegeCode: admit.CollegeCode,
            majorCode: admit.MajorCode,
            majorLearnway: admit.MajorLearningWay
          }
        })
      }
    },
    //点击调剂名单预览
    onAdjustPreviewClick(adjustYear) {
      this.previewAdjustRosterImages = [this.adjustRosterImage(adjustYear)];
      this.adjustPreview = true;
    },
    //点击加入对比
    async onAddCompareClick(admit) {
      await this.addAdmitCompareInfo(admit);
      await this.getAdmitCompareInfos();
      this.$toast.success("加入对比成功。");
    },
    //移除对比
    async onDelCompareClick(index) {
      await this.delAdmitCompareInfo(this.admitCompares[index].CompareGuid);
      await this.getAdmitCompareInfos();
      this.$toast.success("移除对比成功。");
    },
  },
  mounted() {
    this.getRoutParams();
    this.autoLoadData();
    this.getIsMembership();
    this.getAdmitCompareInfos();
    this.addVisitProductRecord();
  }
};
</script>

<style scoped lang="less">
.content-box {
  padding: 5px 0;
}

.admit-list {
  margin-top: 10px;
}

.admit-item {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.admit-divider {
  margin: 20px 0;
}

.admit-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.guide-box {
  background-color: #f6f6f6;
  padding-left: 10px;
  border-width: 0px;
  font-size: 14px;
  font-weight: 600;

  .num-box {
    display: inline;
    letter-spacing: 5px;

    .num1 {
      color: #ff6602;
      font-size: 18px;
      font-weight: 700;
    }

    .num2 {
      color: gray;
      font-size: 14px;
    }
  }
}

.result-box {
  padding: 10px;

  .title {
    color: #37373d;
    font-size: 15px;
    font-weight: normal;
  }

  .row_box {
    padding: 5px 0;
  }

  .txt1 {
    width: 200px;
    text-align: right;
  }

  .txt2 {
    flex: 1;
  }

  .txt {
    color: gray;
    font-size: 14px;
    margin: 5px;
    margin-left: 5px;
    word-spacing: 10px;
  }

  .major_box {
    margin-bottom: 10px;
  }

  .major2_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .major1 {
    color: #ff6602;
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .major2 {
    color: gray;
    font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .admit-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .admit-year {
    font-size: 14px;
    color: #ff6602;
  }

  .admit-tip {
    padding-left: 10px;
    color: red;
    font-size: 13px;
  }

  .adjust-title {
    color: rgb(39, 39, 39);
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .adjust-text {
    display: inline-block;
    white-space: nowrap;
    color: gray;
    font-size: 12px;
    margin: 5px 20px 5px 0;
  }

  .adjust-img {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: 10px;
    min-height: 50px;
  }

  .adjust-lock {
    height: 15px;
    margin-left: 10px;
  }

  .adjust-tip {
    padding-left: 10px;
    color: red;
    font-size: 13px;
  }
}

.card-box {
  background-color: white;
  margin: 10px;
  padding: 5px;
  border-radius: 10px;
}

.empty-txt {
  padding: 10px;
  color: gray;
  font-size: 14px;
}

.report-button {
  width: 90px;
  height: 24px;
  margin-right: 10px;
}

.bottom-line {
  border-top: 1px solid #e6e6e6;
  margin: 10px 0;
}

::v-deep .van-cell-group__title {
  color: black;
  font-weight: bold;
}

::v-deep .van-tabs__nav {
  background-color: #f6f6f6;
}
</style>